import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Drawer, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useApi } from '../../hooks/api';
import IClientsList from '../../interfaces/clients';

interface IProps {
  showFilters: boolean;
  closeFilters: () => void;
  search(
    id: string,
    status: 'ENABLED' | 'DISABLED' | 'ALL',
    name: string,
    media_name: string,
    client_id: string,
    broker: string,
    account: string,
  ): void;
}

const { Option } = Select;

const IntegrationFilters: React.FC<IProps> = props => {
  const { showFilters, closeFilters, search } = props;

  const [clients, setClients] = useState<IClientsList[]>([]);
  const [brokers, setBrokers] = useState<string[]>([]);
  const { api } = useApi();

  const [form] = useForm();
  const loadClients = useCallback(async () => {
    const response = await api.get('/clients');
    setClients(response.data);
  }, [api]);

  const loadBrokers = useCallback(async () => {
    form.setFieldsValue({
      [`broker`]: '',
    });
    const response = await api.get(`/integrations/brokers`);
    setBrokers(response.data);
  }, [api, form]);

  useEffect(() => {
    loadClients();
    loadBrokers();
  }, [loadBrokers, loadClients]);

  const onSearch = useCallback(() => {
    const { id, status, name, media_name, client_id, broker, account } =
      form.getFieldsValue();
    search(
      id === undefined ? '' : id,
      status === undefined ? '' : status,
      name === undefined ? '' : name,
      media_name === undefined ? '' : media_name,
      client_id === undefined ? '' : client_id,
      broker === undefined ? '' : broker,
      account === undefined ? '' : account,
    );
  }, [search, form]);

  const status = [
    { value: 'ENABLED', name: 'ATIVO' },
    { value: 'DISABLED', name: 'INATIVO' },
    { value: 'ALL', name: 'Todos' },
  ];
  const medias = [
    { value: 'whatsapp', name: 'WhatsApp' },
    { value: 'sms', name: 'SMS' },
    { value: 'fbmessenger', name: 'Facebook Messenger' },
    { value: 'fbcomments', name: 'Facebook Comments' },
    { value: 'igdirect', name: 'Instagram Direct' },
    { value: 'igcomments', name: 'Instagram Comments' },
  ];

  return (
    <Drawer
      title="Filtros de pesquisa"
      placement="right"
      closable={false}
      onClose={closeFilters}
      visible={showFilters}
    >
      <Form layout="vertical" onFinish={onSearch} form={form}>
        <Form.Item label="Cliente" name="client_id">
          <Select
            allowClear
            placeholder="Selecione um Cliente"
            className="dcs-select"
            showSearch
            filterOption
            optionFilterProp="children"
          >
            {clients.map(client => {
              return (
                <Option key={client.id} value={client.id}>
                  {client.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Col span={24}>
          <Form.Item label="Integração - ID" name="id">
            <Input
              className="dcs-input"
              allowClear
              type="text"
              placeholder="ex.: Id"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Nome da Integração" name="name">
            <Input
              className="dcs-input"
              allowClear
              type="text"
              placeholder="ex.: Integração"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Canal:" name="media_name">
            <Select
              allowClear
              showSearch
              placeholder="Canal"
              filterOption
              optionFilterProp="children"
              className="dcs-select"
            >
              {medias
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(media => {
                  return (
                    <Option key={media.value} value={media.value}>
                      {media.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Broker:" name="broker">
            <Select
              allowClear
              showSearch
              placeholder="Broker"
              filterOption
              optionFilterProp="children"
              className="dcs-select"
              aria-placeholder="Broker"
            >
              {brokers.map(broker => {
                return (
                  <Option key={broker} value={broker}>
                    {broker}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Conta/Telefone" name="account">
            <Input
              className="dcs-input"
              allowClear
              type="text"
              placeholder="ex.: Conta/Telefone"
            />
          </Form.Item>
        </Col>
        <Form.Item label="Status" name="status">
          <Select
            allowClear
            placeholder="Selecione um Status"
            className="dcs-select"
          >
            {status.map(item => {
              return (
                <Option key={item.value} value={item.value}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" shape="round" htmlType="submit">
            Filtrar
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default IntegrationFilters;
