import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Tabs, Spin, Divider, Col, Space } from 'antd';
import { FaPlus, FaReply } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import {
  PhoneOutlined,
  WhatsAppOutlined,
  FileTextOutlined,
  SendOutlined,
} from '@ant-design/icons';

import './styles.scss';
import { v4 } from 'uuid';
import { IWaba, IWabaPhone, IWabaTemplate } from '../../../interfaces/waba';
import { useAuth } from '../../../hooks/auth';
import { useApi } from '../../../hooks/api';
import WabaDetailInfo from '../../../components/WabaDetailInfo';
import WabaDetailPhones from '../../../components/WabaDetailPhones';
import WabaDetailTemplates from '../../../components/WabaDetailTemplates';
import WabaDetailTestMessage from '../../../components/WabaDetailTestMessage';
import WabaDetailPhoneMigration from '../../../components/WabaDetailPhoneMigration';

const { TabPane } = Tabs;

interface IParams {
  id: string;
}

const WabaDetail: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [loadingWaba, setLoadingWaba] = useState(false);
  const [loadingPhones, setLoadingPhones] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [waba, setWaba] = useState<IWaba>();
  const [phones, setPhones] = useState<IWabaPhone[]>([]);
  const [templates, setTemplates] = useState<IWabaTemplate[]>([]);
  const [showDetails, setShowDetails] = useState(true);
  const [showPhoneMigrationForm, setShowPhoneMigrationForm] = useState(false);

  const { id } = useParams<IParams>();
  const { api } = useApi();
  const isNormalUser = user.user_profile === 'USER';

  const back = useCallback(() => {
    history.goBack();
  }, [history]);

  const loadWabaDetail = useCallback(async () => {
    setLoadingWaba(true);
    try {
      const response = await api.get(`/wabas/${id}`);
      setWaba(response.data);
    } finally {
      setLoadingWaba(false);
    }
  }, [id, api]);

  const loadPhones = useCallback(async () => {
    setLoadingPhones(true);
    try {
      const response = await api.get(`/wabas/${id}/phones`);
      setPhones(response.data);
    } finally {
      setLoadingPhones(false);
    }
  }, [id, api]);

  const loadTemplates = useCallback(async () => {
    setLoadingTemplates(true);
    try {
      const response = await api.get(`/wabas/${id}/templates`);
      setTemplates(response.data);
    } finally {
      setLoadingTemplates(false);
    }
  }, [id, api]);

  const phoneMigration = () => {
    setShowDetails(false);
    setShowPhoneMigrationForm(true);
  };

  const phoneMigrationCancel = useCallback(
    async (refreshPhones?: boolean) => {
      setShowDetails(true);
      setShowPhoneMigrationForm(false);

      if (refreshPhones) {
        loadPhones();
        loadTemplates();
      }
    },
    [setShowDetails, setShowPhoneMigrationForm, loadPhones, loadTemplates],
  );

  useEffect(() => {
    if (id !== undefined) {
      loadWabaDetail();
      loadPhones();
      loadTemplates();
    }
  }, [id, loadWabaDetail, loadPhones, loadTemplates]);

  return (
    <Card className="card-waba-detail">
      <div className="page-header">
        <span>
          <h2>
            {isNormalUser
              ? 'WhatsApp Business Account - Minhas contas'
              : 'WhatsApp Business Account - Contas de cliente'}
          </h2>
          <h3>
            {waba?.waba_id} - {waba?.waba_name}
          </h3>
        </span>
        <Button type="primary" shape="round" icon={<FaReply />} onClick={back}>
          Voltar
        </Button>
      </div>
      <Tabs hidden={!showDetails} defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <WhatsAppOutlined />
              Dados
            </span>
          }
          key="1"
        >
          <Spin spinning={loadingWaba} size="large">
            <WabaDetailInfo waba={waba} />
          </Spin>
        </TabPane>
        <TabPane
          tab={
            <span>
              <PhoneOutlined />
              Telefones
            </span>
          }
          key="2"
        >
          <Spin spinning={loadingPhones} size="large">
            {phones.map(phone => (
              <WabaDetailPhones key={phone.id} waba={waba} phone={phone} />
            ))}
          </Spin>
          <Col span={24}>
            <Space>
              <Button
                type="primary"
                shape="round"
                icon={<FaPlus />}
                htmlType="submit"
                title="Iniciar migração de telefone de outro WABA para este WABA"
                onClick={phoneMigration}
              >
                Migrar telefone
              </Button>
            </Space>
          </Col>
        </TabPane>
        <TabPane
          tab={
            <span>
              <FileTextOutlined />
              Templates
            </span>
          }
          key="3"
        >
          <Spin spinning={loadingTemplates} size="large">
            {templates.map(template => (
              <WabaDetailTemplates key={template.id} template={template} />
            ))}
            <Divider />
          </Spin>
        </TabPane>
        <TabPane
          tab={
            <span>
              <SendOutlined />
              Validar Envio de Mensagem Template
            </span>
          }
          key="4"
        >
          <Spin spinning={loadingPhones && loadingTemplates} size="large">
            <WabaDetailTestMessage
              key={v4()}
              phones={phones}
              templates={templates}
            />
          </Spin>
        </TabPane>
      </Tabs>
      <WabaDetailPhoneMigration
        key={v4()}
        hidden={!showPhoneMigrationForm}
        phoneMigrationCancel={phoneMigrationCancel}
      />
    </Card>
  );
};

export default WabaDetail;
