declare global {
  interface Window {
    fbAsyncInit: any;
    FB: any;
    fbq: any;
  }
}

interface IFbAuthResponse {
  authResponse: {
    accessToken: string;
  };
}

const facebookAppId = 532350928980635;

window.fbAsyncInit = window.fbAsyncInit || {};

export function initFacebookSdk() {
  return new Promise<IFbAuthResponse['authResponse']>((resolve, reject) => {
    const getLoginStatusHandler = (response: IFbAuthResponse) => {
      if (response.authResponse?.accessToken) {
        resolve(response.authResponse);
      } else {
        console.log('Facebook Client Login - Not authenticated yet');
        resolve({} as IFbAuthResponse['authResponse']);
      }
    };

    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: facebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v16.0',
      });

      // auto authenticate with the api if already logged in with facebook
      window.FB.getLoginStatus(getLoginStatusHandler);

      // event listener to capture phoneID e wabaID (only works with version2)
      window.addEventListener('message', event => {
        if (event.origin !== 'https://www.facebook.com') return;
        try {
          const data = JSON.parse(event.data);
          if (data.type === 'WA_EMBEDDED_SIGNUP') {
            if (data.event === 'FINISH') {
              const { phoneID, wabaID } = data.data;
              // todo: do something with the ids...
              console.log(phoneID, wabaID);
            } else {
              console.log(data);
            }
            // ...
          }
        } catch {
          // Don’t parse info that’s not a JSON
          console.log('Non JSON Response', event.data);
        }
      });
    };

    // load facebook sdk script
    (function (d, s, id) {
      const js = d.createElement(s);
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return window.FB.getLoginStatus(getLoginStatusHandler);
      }
      js.id = id;
      (js as HTMLScriptElement).src =
        'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode?.insertBefore(js, fjs);
      return undefined;
    })(document, 'script', 'facebook-jssdk');
  });
}

// Facebook Login with JavaScript SDK
export function launchWhatsAppSignup() {
  return new Promise<IFbAuthResponse['authResponse']>((resolve, reject) => {
    // Conversion tracking code
    // window.fbq &&
    //   window.fbq('trackCustom', 'WhatsAppOnboardingStart', {
    //     appId: facebookAppId,
    //     feature: 'whatsapp_embedded_signup',
    //   });

    const { fbq } = window;

    if (fbq) {
      fbq('trackCustom', 'WhatsAppOnboardingStart', {
        appId: facebookAppId,
        feature: 'whatsapp_embedded_signup',
      });
    }

    // Launch Facebook login
    window.FB.login(
      function (response: IFbAuthResponse) {
        if (response.authResponse?.accessToken) {
          resolve(response.authResponse);
          // Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          reject(new Error('User cancelled login or did not fully authorize.'));
        }
      },
      {
        scope:
          'business_management,whatsapp_business_management,whatsapp_business_messaging',
        // config_id: 921408625654939,
        // auth_type: 'code',
        extras: {
          feature: 'whatsapp_embedded_signup',
          // always orginal embedded sign-up flow regardless of rollout
          version: 1,
          // always new embedded Sign-up flow regardless of rollout
          // version: 2,

          // Get selected phone number ID and WhatsApp Business Account ID (only for version 2)
          // sessionInfoVersion: 1,

          /* setup: {
                ... // Prefilled data can go here
              } */
        },
      },
    );
  });
}
