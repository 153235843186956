/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Drawer, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useApi } from '../../hooks/api';
import IIntegrationsList from '../../interfaces/integrationsList';
import IClientsList from '../../interfaces/clients';

interface IProps {
  showFilters: boolean;
  closeFilters: () => void;
  search(
    media_name: string,
    broker: string,
    client: string,
    integration: string,
    request_sent_at: string,
    origin: string,
  ): void;
}

const { Option } = Select;

const LogIntegrationRequestFilters: React.FC<IProps> = props => {
  const { showFilters, closeFilters, search } = props;
  const [brokers, setBrokers] = useState<string[]>([]);
  const [integrations, setIntegrations] = useState<IIntegrationsList[]>([]);
  const [clients, setClients] = useState<IClientsList[]>([]);

  const { api } = useApi();

  const medias = [
    { value: 'whatsapp', name: 'WhatsApp' },
    { value: 'sms', name: 'SMS' },
    { value: 'fbmessenger', name: 'Facebook Messenger' },
    { value: 'fbcomments', name: 'Facebook Comments' },
    { value: 'igdirect', name: 'Instagram Direct' },
    { value: 'igcomments', name: 'Instagram Comments' },
  ];

  const [form] = useForm();

  const loadClients = useCallback(async () => {
    const response = await api.get('/clients');
    setClients(response.data);
  }, [api]);

  const loadIntegrations = useCallback(async () => {
    const { data } = await api.get(`/integrations`);

    const brokersData = data.reduce((acc: any, curr: any) => {
      if (!acc.includes(curr.broker)) {
        acc.push(curr.broker);
      }
      return acc;
    }, []);

    setIntegrations(data);
    setBrokers(brokersData);
  }, [api]);

  const onSearch = useCallback(() => {
    const { media_name, broker, client, integration, request_sent_at, origin } =
      form.getFieldsValue();

    search(
      media_name === undefined ? '' : media_name,
      broker === undefined ? '' : broker,
      client === undefined ? '' : client,
      integration === undefined ? '' : integration,
      request_sent_at === undefined ? '' : request_sent_at,
      origin === undefined ? '' : origin,
    );
  }, [search, form]);

  useEffect(() => {
    loadIntegrations();
    loadClients();
  }, [loadIntegrations, loadClients]);

  return (
    <Drawer
      title="Filtros de pesquisa"
      placement="right"
      closable={false}
      onClose={closeFilters}
      visible={showFilters}
    >
      <Form layout="vertical" onFinish={onSearch} form={form}>
        <Col span={24}>
          <Form.Item label="Canal:" name="media_name">
            <Select
              allowClear
              showSearch
              placeholder="Canal"
              filterOption
              optionFilterProp="children"
              className="dcs-select"
            >
              {medias
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(media => {
                  return (
                    <Option key={media.value} value={media.value}>
                      {media.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Broker:" name="broker">
            <Select
              allowClear
              showSearch
              placeholder="Broker"
              filterOption
              optionFilterProp="children"
              className="dcs-select"
            >
              {brokers.map(broker => {
                return (
                  <Option key={broker} value={broker}>
                    {broker.toUpperCase()}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Cliente:" name="client">
            <Select
              allowClear
              showSearch
              placeholder="Cliente"
              filterOption
              optionFilterProp="children"
              className="dcs-select"
            >
              {clients.map(client => {
                return (
                  <Option key={client.id} value={client.id}>
                    {client.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Integração:" name="integration">
            <Select
              allowClear
              showSearch
              placeholder="Integração"
              filterOption
              optionFilterProp="children"
              className="dcs-select"
            >
              {integrations.map(integration => {
                return (
                  <Option key={integration.id} value={integration.id}>
                    {integration.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Data" name="request_sent_at">
            <Input className="dcs-input" allowClear type="date" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Origem" name="origin">
            <Input
              className="dcs-input"
              allowClear
              type="text"
              placeholder="ex.: Origem"
            />
          </Form.Item>
        </Col>
        <Form.Item>
          <Button type="primary" shape="round" htmlType="submit">
            Filtrar
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default LogIntegrationRequestFilters;
