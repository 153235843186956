import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import {
  FaCogs,
  FaCommentDollar,
  FaUsers,
  FaUsersCog,
  FaWhatsapp,
} from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import CardStatistics from '../../components/CardStatistics';
import { useApi } from '../../hooks/api';
import { useAuth } from '../../hooks/auth';
import IStatisticsDashboard from '../../interfaces/statisticsDashboard';

const Dashboard: React.FC = () => {
  const { api } = useApi();
  const { user } = useAuth();
  const history = useHistory();

  const [statistics, setStatistics] = useState<IStatisticsDashboard>({
    clients: 0,
    integrationConfigurations: 0,
    integrations: 0,
    licenses: 0,
    users: 0,
  });

  const loadStatistics = useCallback(async () => {
    const response = await api.get('/statistics/dashboard');
    setStatistics(response.data);
  }, [api]);

  useEffect(() => {
    if (user !== undefined) {
      if (user.reset_password) {
        history.push(`/profile?reset_password=true`);
      }
    }

    loadStatistics();
  }, [loadStatistics, user, history]);

  return (
    <div>
      <Row>
        <Col xl={6} md={12} sm={24} xs={24}>
          <CardStatistics
            icon={FaUsersCog}
            name="Usuários"
            statistc={statistics.users}
          />
        </Col>
        <Col xl={6} md={12} sm={24} xs={24}>
          <CardStatistics
            icon={FaUsers}
            name="Clientes"
            statistc={statistics.clients}
          />
        </Col>
        <Col xl={6} md={12} sm={24} xs={24}>
          <CardStatistics
            icon={FaWhatsapp}
            name="Integrações"
            statistc={statistics.integrations}
          />
        </Col>
        <Col xl={6} md={12} sm={24} xs={24}>
          <CardStatistics
            icon={FaCogs}
            name="Brokers"
            statistc={statistics.integrationConfigurations}
          />
        </Col>
        <Col xl={6} md={12} sm={24} xs={24}>
          <CardStatistics
            icon={FaCommentDollar}
            name="Licenças"
            statistc={statistics.licenses}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
