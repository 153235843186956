import React, { ComponentType, useCallback } from 'react';
import { Card } from 'antd';

import './styles.scss';
import { IconBaseProps } from 'react-icons';

interface IProps {
  icon: ComponentType<IconBaseProps>;
  name: string;
  statistc: number;
}

const CardStatistics: React.FC<IProps> = props => {
  const { icon: Icon, name, statistc } = props;

  const formatNumber = useCallback((value: number) => {
    return String(value).padStart(2, '0');
  }, []);

  return (
    <Card className="card-statistics">
      <Icon size={75} color="#C9E265" />
      <div className="info">
        <span className="statistc">{formatNumber(statistc)}</span>
        <span>{name}</span>
      </div>
    </Card>
  );
};

export default CardStatistics;
